import { useContext, useReducer } from "react";
import { Option } from "../../components/questionTypes/SelectableOptions/SelectableOptions";
import { ICreateQuestionRequest } from "../../models/request/ICreateQuestions";
import { QuestionFormContext } from "../Context";
import QuestionFormReducer from "./QuestionFormReducer";

interface QuestionFormContextType {
  questionFormData: IQuestionState;
  setQuestionData: (data: ICreateQuestionRequest) => void;
}

export interface ILoadingState {
  isLoadingForm: boolean;
}

export interface IErrorState {
  errorForm: boolean;
  errorField: string;
  errorMessageForm: string;
  errorFetchForm: boolean;
}

export interface IQuestionState {
  questionForm: ICreateQuestionRequest;
  loadingState: ILoadingState;
  errorState: IErrorState[];
}

export const initialState: IQuestionState = {
  questionForm: {
    questionTitle: "",
    questionType: 0,
    questionScale: 1,
    questionTypeForm: 1,
    surveyId: "",
    isMandatory: false,
    commentAvailable: false,
    options: [],
  },
  loadingState: {
    isLoadingForm: false,
  },
  errorState: [],
};

export const QuestionFormState = (props: any) => {
  const [state, dispatch] = useReducer(QuestionFormReducer, initialState);

  const setQuestionForm = (data: ICreateQuestionRequest) => {
    dispatch({ type: "SET_QUESTION_FORM_DATA", payload: data });
  };

  const setSurveyId = (id: string) => {
    dispatch({ type: "SET_SURVEY_ID", payload: id });
  };

  const setQuestionOptions = (options: Option[]) => {
    dispatch({ type: "SET_QUESTION_OPTIONS", payload: options });
  };

  const setQuestionTitle = (title: string) => {
    dispatch({ type: "SET_QUESTION_TITLE", payload: title });
  };

  const setQuestionType = (type: number) => {
    dispatch({ type: "SET_QUESTION_TYPE", payload: type });
  };

  const setQuestionScale = (scale: number) => {
    dispatch({ type: "SET_QUESTION_SCALE", payload: scale });
  };

  const setQuestionTypeForm = (type: number) => {
    dispatch({ type: "SET_QUESTION_TYPE_FORM", payload: type });
  };

  const setIsMandatory = (isMandatory: boolean) => {
    dispatch({ type: "SET_IS_MANDATORY", payload: isMandatory });
  };

  const setCommentAvailable = (commentAvailable: boolean) => {
    dispatch({ type: "SET_COMMENT_AVAILABLE", payload: commentAvailable });
  };

  const setLoadingState = (isLoading: boolean) => {
    dispatch({ type: "SET_LOADING_STATE", payload: isLoading });
  };

  const setErrorState = (error: IErrorState[]) => {
    dispatch({ type: "SET_ERROR_STATE", payload: error });
  };

  const resetQuestionOptions = () => {
    dispatch({ type: "RESET_QUESTION_OPTIONS" });
  };

  const resetQuestionForm = () => {
    dispatch({ type: "RESET_QUESTION_FORM_DATA" });
  };

  return (
    <QuestionFormContext.Provider
      value={{
        ...state,
        setQuestionForm,
        setSurveyId,
        setQuestionOptions,
        setQuestionTitle,
        setQuestionType,
        setQuestionScale,
        setQuestionTypeForm,
        setIsMandatory,
        setCommentAvailable,
        setLoadingState,
        setErrorState,
        resetQuestionOptions,
        resetQuestionForm,
      }}
    >
      {props.children}
    </QuestionFormContext.Provider>
  );
};

export const useQuestionForm = () => {
  const context = useContext(QuestionFormContext);
  if (context === undefined) {
    throw new Error(
      "useQuestionForm must be used within a QuestionFormProvider"
    );
  }
  return context;
};
