import { useContext, useState } from "react";
import StarsRating from "react-star-rate";
import { QuestionContext } from "../../../context/Context";
import Textarea from "../../ui/Textarea/Textarea";

const Csat = (props: { question: any; type?: number }) => {
  const questionContext = useContext(QuestionContext);
  const { surveyInfo } = questionContext;
  const questionTypeFormId = props.question.questionTypeFormId
    ? props.question.questionTypeFormId
    : 1;
  const [rating, setRating] = useState(0);
  const { commentAvailable } = props.question;
  const [comment, setComment] = useState<string | null>();

  const handleOption = (value: number) => {
    setRating(value);
    const question = { ...props.question };
    if (surveyInfo.channel !== "DEMO" && question.response !== value) {
      question.response = value;
      questionContext.saveResponse(question);
    }
  };

  const handleChangeComment = (comment = null) => {
    setComment(comment);
    const question = { ...props.question };
    if (surveyInfo.channel !== "DEMO") {
      question.comment = comment;
      questionContext.saveResponse(question);
    }
  };

  const options = [
    {
      id: "very_bad_1_" + props.question.questionId,
      value: 1,
      label: "Very bad",
      smile: "smile_1",
    },
    {
      id: "bad_1_" + props.question.questionId,
      value: 2,
      label: "Bad",
      smile: "smile_2",
    },
    {
      id: "average_1_" + props.question.questionId,
      value: 3,
      label: "Average",
      smile: "smile_3",
    },
    {
      id: "good_1_" + props.question.questionId,
      value: 4,
      label: "Good",
      smile: "smile_4",
    },
    {
      id: "very_good_1_" + props.question.questionId,
      value: 5,
      label: "Very Good",
      smile: "smile_5",
    },
  ];

  return (
    <div>
      {questionTypeFormId === 1 ? (
        <div className="review_block_smiles">
          <ul className="clearfix">
            {options
              .filter((_, index) =>
                props.question.optionScale === 1 ? index % 2 === 0 : true
              )
              .map((option) => {
                const nameRadio = "question_" + props.question.questionId;
                return (
                  <li key={option.id}>
                    <div className="container_smile">
                      <input
                        type="radio"
                        id={option.id}
                        name={nameRadio}
                        className="required"
                        onClick={() => handleOption(option.value)}
                      />
                      <label
                        className={"radio " + option.smile}
                        htmlFor={option.id}
                      >
                        <span>{option.label}</span>
                      </label>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      ) : (
        <div className={`${surveyInfo.channel !== "DEMO" ? "mt-4" : "mt-5"}`}>
          <StarsRating
            allowHalf={false}
            value={rating}
            count={props.question.optionScale === 1 ? 3 : 5}
            onChange={(value: any) => {
              const { optionScale } = props.question;
              if (rating === value) {
                setRating(0);
              } else {
                if (optionScale === 1) {
                  if (value === 3) {
                    value = 5;
                  } else if (value === 2) {
                    value = 3;
                  }
                }
                handleOption(value);
              }
            }}
          />
        </div>
      )}
      <div className="row justify-content-between add_bottom_25">
        <div className="col-6">
          <em>Muy insatisfecho</em>
        </div>
        <div className="col-6 text-end">
          <em>Muy satisfecho</em>
        </div>
      </div>
      {commentAvailable && rating > 0 && surveyInfo.channel !== "DEMO" && (
        <div className="nps-container mx-auto mt-4 animate__animated animate__fadeInDown">
          <Textarea
            label=" "
            onChange={(e: any) => handleChangeComment(e)}
            value={comment}
            placeholder={"Agrega tus comentarios (opcional)..."}
            rows={4}
          />
        </div>
      )}
    </div>
  );
};

export default Csat;
