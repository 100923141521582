import { useContext } from "react";
import { QuestionContext, QuestionFormContext } from "../context/Context";

// generate type for the enum e.data.type
enum MessageType {
  GENERAL = "GENERAL",
  FORMAT_TYPE = "FORMAT_TYPE",
  MAIN_BG_TYPE = "MAIN_BG_TYPE",
  CONTENT_BG_TYPE = "CONTENT_BG_TYPE",
  QUESTION = "QUESTION",
  GET_QUESTIONS = "GET_QUESTIONS",
  NEW_QUESTION = "NEW_QUESTION",
  RESET = "RESET",
}

const usePanelAdminBinding = () => {
  const questionContext = useContext(QuestionContext);
  const {
    questions,
    surveyInfo,
    saveSurveyInfo,
    updateStepWizard,
    saveIsVisibleEditForm,
    saveIsVisibleCreateForm,
    saveEditMode,
    saveEmptySurvey,
    saveQuestions,
    isVisibleCreateForm,
    getQuestions,
  } = questionContext;

  const questionFormContext = useContext(QuestionFormContext);
  const { setQuestionForm } = questionFormContext;

  const bindingPanelMF = () => {
    window.onmessage = function (e) {
      if (e.data && e.data?.type === MessageType.GENERAL) {
        const isFirstQuestion = e.data.isFirstQuestion;
        const editMode = e.data.editMode;
        const token = e.data.token ? e.data.token : null;

        saveEditMode(editMode, token, isFirstQuestion);

        if (isFirstQuestion && questions.length < 1) {
          validateWhenActionIsNewQuestion(true);
          saveEmptySurvey(false);
        }
      }

      if (e.data && e.data?.type === MessageType.FORMAT_TYPE) {
        saveSurveyInfo({
          ...surveyInfo,
          formatType: e.data?.formatType
            ? e.data.formatType
            : surveyInfo.formatType,
        });
      }

      if (e.data && e.data?.type === MessageType.MAIN_BG_TYPE) {
        saveSurveyInfo({
          ...surveyInfo,
          mainBgType: e.data?.mainBgType
            ? e.data.mainBgType
            : surveyInfo.mainBgType,
        });
      }

      if (e.data && e.data?.type === MessageType.CONTENT_BG_TYPE) {
        saveSurveyInfo({
          ...surveyInfo,
          contentBgType: e.data?.contentBgType
            ? e.data.contentBgType
            : surveyInfo.contentBgType,
        });
      }

      if (e.data && e.data?.type === MessageType.QUESTION) {
        const question = e.data.question ?? null;
        const isVisibleEditForm = e.data.isVisibleEditForm ?? false;

        if (!question) return;

        setQuestionForm({
          surveyId: surveyInfo.surveyId,
          questionId: question.questionId,
          questionTitle: question.questionTitle,
          questionType: question.questionType,
          questionScale: question.optionScale,
          questionTypeForm: 1,
          isMandatory: question.isMandatory,
          commentAvailable: question.commentAvailable,
          options: question.options,
        });
        updateStepWizard(question.orden);
        saveIsVisibleEditForm(isVisibleEditForm);
      }

      if (e.data && e.data?.type === MessageType.NEW_QUESTION) {
        isVisibleCreateForm === false &&
          validateWhenActionIsNewQuestion(Boolean(e.data.isVisibleCreateForm));
      }

      if (e.data && e.data?.type === MessageType.GET_QUESTIONS) {
        const { surveyId } = e.data;
        updateStepWizard(1);
        getQuestions(surveyId, "DEMO");
      }

      if (e.data && e.data?.type === MessageType.RESET) {
        window.location.reload();
      }
    };
  };

  const validateWhenActionIsNewQuestion = (
    isVisibleCreateFormProp: boolean
  ) => {
    const newQuestion = {
      questionId: null,
      options: [],
      title: null,
    };
    const newQuestions = [...questions, ...[newQuestion]];

    saveQuestions(newQuestions);
    updateStepWizard(newQuestions.length);
    saveIsVisibleCreateForm(isVisibleCreateFormProp);
  };

  return {
    bindingPanelMF,
  };
};

export default usePanelAdminBinding;
