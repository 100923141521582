import { faEdit, faGrip, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { QuestionContext, QuestionFormContext } from "../../../context/Context";

export interface Option {
  id: string;
  value: string;
}

interface SelectableOptionsProps {
  question?: any;
  options: Option[];
  allowMultiple?: boolean;
  inlineOptions?: boolean;
  enableDragDrop?: boolean;
  onOptionChange?: Function;
}

const SelectableOptions: React.FC<SelectableOptionsProps> = ({
  question,
  options = [],
  allowMultiple = false,
  inlineOptions = false,
  enableDragDrop = false,
  onOptionChange,
}) => {
  const questionContext = useContext(QuestionContext);
  const { surveyInfo } = questionContext;

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [hoveredItemId, setHoveredItemId] = useState<string | null>(null);

  const questionFormContext = useContext(QuestionFormContext);
  const { setQuestionOptions } = questionFormContext;

  const handleOptionChange = (optionId: string) => {
    let newSelectedOptions: string[] = [];
    if (allowMultiple) {
      if (selectedOptions.includes(optionId)) {
        newSelectedOptions = selectedOptions.filter((id) => id !== optionId);
      } else {
        newSelectedOptions = [...selectedOptions, optionId];
      }
    } else {
      newSelectedOptions = [optionId];
    }

    setSelectedOptions(newSelectedOptions);

    if (surveyInfo.channel !== "DEMO" && question.response !== optionId) {
      question.response = allowMultiple
        ? JSON.stringify(newSelectedOptions)
        : optionId;
      questionContext.saveResponse(question);
    }
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(options);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setQuestionOptions(items);
  };

  const handleDeleteOption = (optionId: string) => {
    const newOptions = options.filter((option) => option.id !== optionId);
    setQuestionOptions(newOptions);
  };

  const handleEditOption = (optionId: string) => {
    const option = options.find((option) => option.id === optionId);
    if (option) {
      onOptionChange && onOptionChange(option.id);
    }
  };

  return (
    <>
      {enableDragDrop ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {options.map((option, index) => (
                  <Draggable
                    key={option.id}
                    draggableId={option.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`form-group px-3`}
                        style={{
                          ...provided.draggableProps.style,
                          top: "calculated top position based on container",
                          left: "calculated left position based on container",
                        }}
                        onMouseEnter={() => setHoveredItemId(option.id)}
                        onMouseLeave={() => setHoveredItemId(null)}
                      >
                        <label className="container_check version_2">
                          {option.value}
                          <input
                            type="checkbox"
                            name={`question_${option.value}[]`}
                            value={option.value}
                            checked={selectedOptions.includes(option.id)}
                            onChange={() => handleOptionChange(option.id)}
                          />
                          <span className="checkmark"></span>
                          <div className="option-grip py-2 px-3">
                            <FontAwesomeIcon
                              icon={faGrip}
                              className="icon-grip"
                            />
                          </div>
                          {hoveredItemId === option.id && (
                            <>
                              <button
                                type="button"
                                onClick={() => handleDeleteOption(option.id)}
                                className="btn_1 delete text-center option-btn py-2 px-3"
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </button>
                              <button
                                type="button"
                                onClick={() => handleEditOption(option.id)}
                                className="btn_1 edit text-center option-btn-2 py-2 px-3"
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </button>
                            </>
                          )}
                        </label>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className="row">
          {options?.map((option) => (
            <div
              key={option.id}
              className={`form-group px-3 ${
                inlineOptions ? "col-12" : "col-12 col-md-6"
              }`}
            >
              <label className="container_check version_2">
                {option.value}
                <input
                  type="checkbox"
                  name={`question_${option.value}[]`}
                  value={option.value}
                  checked={selectedOptions.includes(option.id)}
                  onChange={() => handleOptionChange(option.id)}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SelectableOptions;
