import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { QuestionContext, QuestionFormContext } from "../../context/Context";
import { IErrorState } from "../../context/QuestionFormContext/QuestionFormState";
import useQuestionForm from "../../hooks/useQuestionForm";
import InfoAlert from "../InfoAlert/InfoAlert";
import Csat from "../questionTypes/Csat/Csat";
import Nps from "../questionTypes/Nps/Nps";
import Select from "../ui/Select/Select";
import Textarea from "../ui/Textarea/Textarea";
import ToggleSwitch from "../ui/ToggleSwitch/ToggleSwitch";
import MultipleOptionForm from "./MultipleOptionForm/MultipleOptionForm";

const TOOLTIPO_TEXT_SCALE_1 =
  "• Escala de 1 a 3: Esta escala es más sencilla y puede ser ideal para preguntas más simples o cuando se prefiere una respuesta más directa.";
const TOOLTIPO_TEXT_SCALE_2 =
  "• Escala de 1 a 5: Este tipo de escala permite una evaluación más detallada, ideal para cuando se requiere una precisión más alta en las respuestas.";
const TOOLTIPO_TEXT_MANDATORY =
  "• Pregunta obligatoria: Si activas esta opción, los encuestados deberán responder obligatoriamente a esta pregunta para poder continuar con la encuesta. Se recomienda utilizarla en preguntas cruciales cuya respuesta es necesaria para el análisis de los resultados.";
const TOOLTIPO_TEXT_COMMENT =
  "• Habilitar comentarios: Al activar esta opción, los encuestados podrán dejar un comentario o proporcionar detalles adicionales en relación a su respuesta. Esto puede ser útil para recopilar más información o entender mejor las razones detrás de sus calificaciones.";

const QuestionForm = () => {
  const { handleCancel, saveQuestionFetch } = useQuestionForm();

  const questionContext = useContext(QuestionContext);
  const {
    questions,
    questionTypes,
    stepWizard,
    isVisibleCreateForm,
    surveyInfo,
    scaleCSAT,
    typeCSAT,
    typeNPS,
  } = questionContext;

  const questionFormContext = useContext(QuestionFormContext);
  const {
    questionForm: {
      questionTitle,
      questionType,
      questionScale,
      questionTypeForm,
      isMandatory,
      commentAvailable,
    },
    errorState,
    loadingState: { isLoadingForm },
    setSurveyId,
    setQuestionTitle,
    setQuestionType,
    setQuestionScale,
    setQuestionTypeForm,
    setIsMandatory,
    setCommentAvailable,
    setErrorState,
    resetQuestionForm,
    setQuestionOptions,
  } = questionFormContext;

  const [actualQuestion, setActualQuestion] = useState({
    ...questions[stepWizard - 1],
  });

  useEffect(() => {
    setSurveyId(surveyInfo?.surveyId || "");
    setQuestionTitle(actualQuestion?.title || "");
    setQuestionType(actualQuestion?.typeId || 0);
    setQuestionScale(actualQuestion?.optionScale || 2);
    setQuestionTypeForm(actualQuestion?.questionTypeFormId || 1);
    setIsMandatory(actualQuestion?.isMandatory || false);
    setCommentAvailable(actualQuestion?.commentAvailable || false);
    setQuestionOptions(actualQuestion?.options || []);
  }, []);

  useEffect(() => {
    if (isVisibleCreateForm) {
      resetQuestionForm();
      setActualQuestion({});
    }
  }, [isVisibleCreateForm]);

  return (
    <>
      {/* PREGUNTAS BASE - APLCA PARA TODOS TIPOS DE PREGUNTA */}
      <div className="wrapper row">
        {actualQuestion?.hasActivity && (
          <div className="mb-3">
            <InfoAlert
              heading="🚨Atención"
              message="Esta pregunta ya ha recibido interacciones y, para mantener la integridad de los datos, <b>no se puede modificar</b>. Si necesitas hacer cambios, considera crear una nueva pregunta."
              footer="Si tienes alguna duda, consulta nuestra sección de preguntas frecuentes o ponte en contacto con nuestro equipo de soporte."
            />
          </div>
        )}
        <div className="col-12 col-md-8">
          <Textarea
            label="Pregunta"
            value={questionTitle}
            onChange={(value: string) => {
              setQuestionTitle(value);
              if (value?.trim().length > 5) {
                const newErrorState = [...errorState].filter(
                  (error) => error.errorField !== "questionTitle"
                );
                setErrorState(newErrorState);
              } else {
                const newError: IErrorState = {
                  errorForm: true,
                  errorField: "questionTitle",
                  errorMessageForm:
                    "Debes ingresar la pregunta con al menos 5 caracteres",
                  errorFetchForm: false,
                };
                const newErrorState = [...errorState, newError];
                setErrorState(newErrorState);
              }
            }}
            maxLength={200}
            isError={
              errorState.find(
                (error: IErrorState) => error.errorField === "questionTitle"
              )?.errorForm
            }
            errorMessage={
              errorState.find(
                (error: IErrorState) => error.errorField === "questionTitle"
              )?.errorMessageForm
            }
            disabled={actualQuestion?.hasActivity}
          />
        </div>
        <div className="col-12 col-md-4 col-12 col-md-4 mt-3 mt-md-0">
          <Select
            options={questionTypes}
            value={questionType}
            onChange={(value) => {
              if (value === 0) {
                const newError: IErrorState = {
                  errorForm: true,
                  errorField: "questionType",
                  errorMessageForm: "Debes seleccionar un tipo de pregunta",
                  errorFetchForm: false,
                };

                const newErrorState = [...errorState, newError];
                setErrorState(newErrorState);
              } else {
                const newErrorState = [...errorState].filter(
                  (error) => error.errorField !== "questionType"
                );
                setErrorState(newErrorState);
              }
              setQuestionType(value);
            }}
            label="Tipo de pregunta"
            disabled={actualQuestion?.hasActivity}
            isError={
              errorState.find(
                (error: IErrorState) => error.errorField === "questionType"
              )?.errorForm
            }
            errorMessage={
              errorState.find(
                (error: IErrorState) => error.errorField === "questionType"
              )?.errorMessageForm
            }
          />
        </div>
      </div>

      <hr className="mx-0" />

      <div className="wrapper" style={{ marginBottom: "8px" }}>
        <div className="d-flex justify-content-end">
          {/* ES PREGUNTA OBLIGATORIA - APLICA PARA TODOS LOS TIPOS DE PREGUNTAS */}
          {questionType !== 0 && (
            <div className="text-right" style={{ marginRight: "32px" }}>
              <label>
                Pregunta obligatoria{" "}
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{
                    color: "#3498db",
                    fontSize: "12px",
                    cursor: "help",
                  }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={TOOLTIPO_TEXT_MANDATORY}
                />
                <Tooltip
                  id="my-tooltip"
                  place="left-end"
                  data-tooltip-variant="dark"
                  style={{ width: "300px", fontSize: "12px" }}
                />
              </label>
              <div>
                <ToggleSwitch
                  value={isMandatory}
                  handleChange={(value) => setIsMandatory(value)}
                />
              </div>
            </div>
          )}

          {/* COMENTARIO SOLO PARA PREGUNTAS DE SATISFACCION AL CLIENTE */}
          {(questionType === 1 || questionType === 2) && (
            <div className="text-right">
              <label>
                Habilitar comentarios{" "}
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{
                    color: "#3498db",
                    fontSize: "12px",
                    cursor: "help",
                  }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={TOOLTIPO_TEXT_COMMENT}
                />
                <Tooltip
                  id="my-tooltip"
                  place="left-end"
                  data-tooltip-variant="dark"
                  style={{ width: "300px", fontSize: "12px" }}
                />
              </label>
              <div>
                <ToggleSwitch
                  value={commentAvailable}
                  handleChange={(value) => setCommentAvailable(value)}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {/* NPS */}
      {questionType === 1 && (
        <>
          <div className="wrapper row">
            <div className="col-12 col-md-6">
              <Select
                options={scaleCSAT}
                value={questionScale}
                onChange={(value) => {
                  setQuestionScale(value);
                  const updateActualQuestion = {
                    ...actualQuestion,
                    optionScale: value,
                  };
                  setActualQuestion(updateActualQuestion);
                }}
                label="Escala"
                disabled={actualQuestion?.hasActivity}
                isTooltipActive={true}
                tooltipText={
                  questionScale === 1
                    ? TOOLTIPO_TEXT_SCALE_1
                    : TOOLTIPO_TEXT_SCALE_2
                }
              />
            </div>
            <div className="col-12 col-md-6 col-12 col-md-4 mt-3 mt-md-0">
              <Select
                options={typeCSAT}
                value={questionTypeForm}
                onChange={(value) => {
                  setQuestionTypeForm(value);
                  const updateActualQuestion = {
                    ...actualQuestion,
                    questionTypeFormId: value,
                  };
                  setActualQuestion(updateActualQuestion);
                }}
                label="Forma"
                disabled={actualQuestion?.hasActivity}
              />
            </div>
            <div className="col-12 mt-3">
              <Csat question={actualQuestion} type={questionTypeForm} />
            </div>
          </div>
        </>
      )}

      {/* NPS */}
      {questionType === 2 && (
        <>
          <div className="wrapper row">
            <div className="col-12 col-md-6 col-12 col-md-4 mt-3 mt-md-0">
              <Select
                options={typeNPS}
                value={questionTypeForm}
                onChange={(value) => {
                  setQuestionTypeForm(value);
                  const updateActualQuestion = {
                    ...actualQuestion,
                    questionTypeFormId: value,
                  };
                  setActualQuestion(updateActualQuestion);
                }}
                label="Forma"
                disabled={actualQuestion?.hasActivity}
              />
            </div>
            <div className="col-12 mt-3">
              <Nps question={actualQuestion} />
            </div>
          </div>
        </>
      )}

      {/* SELECCION MULTIPLE */}
      {questionType === 3 && (
        <MultipleOptionForm
          isEditable={!actualQuestion?.hasActivity}
          allowMultiple={true}
          errorMessage={
            errorState.find(
              (error: IErrorState) => error.errorField === "options"
            )?.errorMessageForm
          }
        />
      )}

      {/* SELECCION ÚNICA */}
      {questionType === 4 && (
        <MultipleOptionForm
          isEditable={!actualQuestion?.hasActivity}
          allowMultiple={false}
          errorMessage={
            errorState.find(
              (error: IErrorState) => error.errorField === "options"
            )?.errorMessageForm
          }
        />
      )}

      {/* BUTTONS ACTIONS */}
      <div className="">
        {questionType !== 0 && <hr className="mx-0" />}

        <button
          type="button"
          name="backward"
          className="btn_1 small success float-right"
          onClick={() => {
            let payload = {
              ...questionFormContext.questionForm,
              commentAvailable: commentAvailable,
              surveyId: surveyInfo?.surveyId,
            };

            if (questionType === 5 || questionType === 6) {
              payload.commentAvailable = true;
              setCommentAvailable(true);
            }

            saveQuestionFetch(payload, actualQuestion);
          }}
          disabled={actualQuestion?.hasActivity || isLoadingForm}
        >
          {isLoadingForm ? "Guardando..." : "Guardar"}
        </button>
        {questions.length > 1 && (
          <button
            type="button"
            name="backward"
            className="btn_1 small cancel float-right mb-md-0 mr-md-2"
            onClick={() => handleCancel()}
          >
            Cancelar
          </button>
        )}
      </div>
    </>
  );
};
export default QuestionForm;
