import { useContext, useEffect, useRef, useState } from "react";
import "../../../assets/sass/components/form-inputs.scss";
import { QuestionContext } from "../../../context/Context";

const Textarea = (props: {
  question?: any;
  value: any;
  maxLength?: number;
  onChange?: Function;
  isError?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  rows?: number;
}) => {
  const questionContext = useContext(QuestionContext);
  const { surveyInfo } = questionContext;

  const [val, setVal] = useState(props.value);
  const textAreaRef: any = useRef(null);

  const resizeTextArea = () => {
    textAreaRef.current.style.height = "auto";
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
  };

  useEffect(() => {
    setVal(props.value);
  }, [props.value]);

  useEffect(resizeTextArea, [val]);

  const handleChange = (e: any) => {
    const { value } = e.target;
    setVal(value);
    if (props.onChange) return props.onChange(value);

    const { question } = props;
    if (
      question &&
      surveyInfo.channel !== "DEMO" &&
      question.response !== value
    ) {
      question.comment = value;
      question.response = value;
      questionContext.saveResponse(question);
    }
  };

  return (
    <>
      <div className="form-group w-100">
        <label>{props.label && props.label}</label>
        <textarea
          name="logemail"
          className="form-style"
          placeholder={
            props.placeholder ? props.placeholder : "Ingresa tu pregunta"
          }
          id="logemail"
          maxLength={props.maxLength ? props.maxLength : 200}
          ref={textAreaRef}
          value={val}
          onChange={handleChange}
          rows={props.rows ? props.rows : 1}
          disabled={props.disabled}
        />
        {props.isError && props.errorMessage && (
          <small className="text-danger">{props.errorMessage}</small>
        )}
      </div>
    </>
  );
};

export default Textarea;
