export const filterUniqueErrorFields = (items: any[], field: string): any[] => {
  if (!items) return [];
  if (items.length === 0) return [];
  const validItems = items.filter(
    (item) => item !== undefined && item !== null
  );

  const seen: { [key: string]: boolean } = {};
  const filteredItems = validItems.filter((item) => {
    if (!seen[item[field]]) {
      seen[item[field]] = true;
      return true;
    }
    return false;
  });

  return filteredItems;
};
