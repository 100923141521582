import { filterUniqueErrorFields } from "../../utils/array.util";
import { IQuestionState, initialState } from "./QuestionFormState";
import { QuestionFormAction } from "./types";

type State = IQuestionState;

const QuestionFormReducer = (
  state: State,
  action: QuestionFormAction
): State => {
  switch (action.type) {
    case "SET_QUESTION_FORM_DATA":
      return {
        ...state,
        ...action.payload,
      };
    case "RESET_QUESTION_FORM_DATA":
      return {
        ...initialState,
        questionForm: {
          ...initialState.questionForm,
        },
      };
    case "SET_LOADING_STATE":
      return {
        ...state,
        loadingState: {
          isLoadingForm: action.payload,
        },
      };
    case "SET_ERROR_STATE":
      const newErrorState = [...action.payload];

      return {
        ...state,
        errorState: filterUniqueErrorFields(newErrorState, "errorField"),
      };
    case "SET_QUESTION_TITLE":
      return {
        ...state,
        questionForm: {
          ...state.questionForm,
          questionTitle: action.payload,
        },
      };
    case "SET_QUESTION_OPTIONS":
      return {
        ...state,
        questionForm: {
          ...state.questionForm,
          options: action.payload,
        },
      };
    case "RESET_QUESTION_OPTIONS":
      return {
        ...state,
        questionForm: {
          ...state.questionForm,
          options: [],
        },
      };
    case "SET_QUESTION_TYPE":
      return {
        ...state,
        questionForm: {
          ...state.questionForm,
          questionType: action.payload,
        },
      };
    case "SET_QUESTION_SCALE":
      return {
        ...state,
        questionForm: {
          ...state.questionForm,
          questionScale: action.payload,
        },
      };
    case "SET_QUESTION_TYPE_FORM":
      return {
        ...state,
        questionForm: {
          ...state.questionForm,
          questionTypeForm: action.payload,
        },
      };
    case "SET_SURVEY_ID":
      return {
        ...state,
        questionForm: {
          ...state.questionForm,
          surveyId: action.payload,
        },
      };
    case "SET_IS_MANDATORY":
      return {
        ...state,
        questionForm: {
          ...state.questionForm,
          isMandatory: action.payload,
        },
      };
    case "SET_COMMENT_AVAILABLE":
      return {
        ...state,
        questionForm: {
          ...state.questionForm,
          commentAvailable: action.payload,
        },
      };

    default:
      throw new Error(
        `Unhandled action type: ${(action as QuestionFormAction).type}`
      );
  }
};

export default QuestionFormReducer;
