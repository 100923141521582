import { useContext, useState } from "react";
import { QuestionContext } from "../../../context/Context";
import Textarea from "../../ui/Textarea/Textarea";
import "./nps-small.scss";

const Nps = (props: { question: any }) => {
  const [response, setResponse] = useState<number>();
  const [comment, setComment] = useState<string | null>();
  const questionContext = useContext(QuestionContext);
  const { surveyInfo } = questionContext;
  const questionTypeFormId = props.question.questionTypeFormId
    ? props.question.questionTypeFormId
    : 1;
  const { commentAvailable } = props.question;

  const handleChange = (value: number) => {
    const question = { ...props.question };
    if (surveyInfo.channel !== "DEMO" && question.response !== value) {
      setResponse(value);
      question.response = value;
      questionContext.saveResponse(question);
    }
  };

  const handleChangeComment = (comment = null) => {
    setComment(comment);
    const question = { ...props.question };
    if (questionContext.channel !== "DEMO") {
      question.comment = comment;
      questionContext.saveResponse(question);
    }
  };

  const getButtonClassNps = (index: number, response: number | undefined) => {
    let buttonType = "";
    if (index <= 5) buttonType = "detractor";
    else if (index <= 8) buttonType = "passive";
    else buttonType = "promoter";

    return `nps--widget__button ${buttonType} ${
      response === index ? `${buttonType}-active` : ""
    }`;
  };

  const getButtonClassSmallNps = (index: number) => {
    let buttonType = "";
    if (index <= 5) buttonType = "detractor";
    else if (index <= 8) buttonType = "passive";
    else buttonType = "promoter";

    return `nps--widget__button ${buttonType} ${
      response === index ? ` active` : ""
    }`;
  };

  const getButtonStyleSmallNps = (index: number) => {
    switch (index) {
      case 0:
        return { backgroundColor: "#ff9eae" };
      case 1:
      case 2:
        return { backgroundColor: "#ffafbc" };
      case 3:
        return { backgroundColor: "#ffc0cb" };
      case 4:
        return { backgroundColor: "#ffd1d9" };
      case 5:
        return { backgroundColor: "#ffe2e7" };
      case 9:
        return { backgroundColor: "#adecba" };
      case 10:
        return { backgroundColor: "#5ad974" };
      default:
        return {};
    }
  };

  return (
    <>
      <div className="step animate__animated animate__fadeInRight">
        {questionTypeFormId === 1 && (
          <div className="nps--widget">
            {Array.from({ length: 11 }, (_, index) => (
              <button
                key={index}
                type="button"
                className={getButtonClassNps(index, response)}
                onClick={() => handleChange(index)}
              >
                {index}
              </button>
            ))}
          </div>
        )}
        {questionTypeFormId === 2 && (
          <div className="nps-container mx-auto">
            {Array.from({ length: 11 }, (_, index) => (
              <button
                key={index}
                type="button"
                className={getButtonClassSmallNps(index)}
                style={getButtonStyleSmallNps(index)}
                onClick={() => handleChange(index)}
              >
                {index}
              </button>
            ))}
          </div>
        )}
        <div className="row justify-content-between add_bottom_25">
          <div className="col-6">
            <em>Poco probable</em>
          </div>
          <div className="col-6 text-end">
            <em>Extremadamente probable</em>
          </div>
        </div>

        {commentAvailable &&
        null != response &&
        questionContext.channel !== "DEMO" ? (
          <div className="nps-container mx-auto mt-4 animate__animated animate__fadeInDown">
            <Textarea
              label=" "
              onChange={(e: any) => handleChangeComment(e)}
              value={comment}
              placeholder={"Agrega tus comentarios (opcional)..."}
              rows={4}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Nps;
