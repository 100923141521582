import { useContext } from "react";
import logoDark from "../../assets/img/logos//dark-large-logo.png";
import { default as logoLight } from "../../assets/img/logos/light-large-logo.png";
import { QuestionContext } from "../../context/Context";

const Header = () => {
  const questionContext = useContext(QuestionContext);
  const { surveyInfo } = questionContext;
  const urlRedirect =
    surveyInfo.channel === "DEMO"
      ? "javascript:void(0)"
      : "https://www.empati.cl";

  const getLogoSurvey = () => {
    if (surveyInfo.logoUrl) return surveyInfo.logoUrl;
    return surveyInfo.mainBgType === "light" ? logoDark : logoLight;
  };

  return (
    <header>
      <div className="container-fluid">
        <a href={urlRedirect}>
          <img
            className="img-fluid img-header-layout d-none d-md-inline ml-auto"
            height={45}
            src={getLogoSurvey()}
            alt=""
          />
          <img
            className="img-fluid img-header-layout d-inline d-md-none"
            height={45}
            src={getLogoSurvey()}
            alt=""
          />
        </a>
        <h1>Encuesta servicio al cliente</h1>
        {/* <div className="top_elements">
                    <a href="#0" className="cd-nav-trigger">Menu<span className="cd-icon"></span></a>
                    <nav>
                        <ul className="cd-primary-nav">
                            <li><a href="index.html" className="animated_link">Questionnaire without branch</a></li>
                            <li><a href="index-2.html" className="animated_link">Questionnaire with branch</a></li>
                            <li><a href="prevention.html" className="animated_link">Prevention Tips</a></li>
                            <li><a href="faq.html" className="animated_link">Faq</a></li>
                            <li><a href="contacts.html" className="animated_link">Contact Us</a></li>
                            <li><a href="#0" className="animated_link">Purchase Template</a></li>
                        </ul>
                    </nav>
                </div> */}
      </div>
    </header>
  );
};

export default Header;
