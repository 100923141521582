const OPTIONS = [
  { id: 1, name: "Escala de satisfacción (CSAT)" },
  { id: 2, name: "Escala de opinión (NPS)" },
];

const SCALE_NPS = [
  { id: 1, name: "0 al 10" },
  { id: 2, name: "1 al 10" },
];

const SCALE_CSAT = [
  {
    id: 1,
    name: "1 al 3",
    dataTip:
      "Escala de 1 a 3: Esta escala es más sencilla y puede ser ideal para preguntas más simples o cuando se prefiere una respuesta más directa.",
  },
  {
    id: 2,
    name: "1 al 5",
    dataTip:
      "Escala de 1 a 5: Este tipo de escala permite una evaluación más detallada, ideal para cuando se requiere una precisión más alta en las respuestas.",
  },
];

const TYPE_CSAT = [
  { id: 1, name: "Emojis Smile" },
  { id: 2, name: "Estrella" },
];

const TYPE_NPS = [
  { id: 1, name: "Números v1" },
  { id: 2, name: "Números v2" },
];

export { OPTIONS, SCALE_CSAT, SCALE_NPS, TYPE_CSAT, TYPE_NPS };
