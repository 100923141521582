import Cookies from "js-cookie";
import { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { getQuestionsTypeList } from "../../services/catalog.service";
import {
  generateSurveySession,
  getQuestionsByIdSurvey,
  saveResponseSurvey,
} from "../../services/survey.service";
import { KEY_QUESTIONS_STORE } from "../../utils/constants/config";
import {
  OPTIONS,
  SCALE_CSAT,
  SCALE_NPS,
  TYPE_CSAT,
  TYPE_NPS,
} from "../../utils/constants/question-catalog";
import { encryptMessage } from "../../utils/crypto/encrypt";
import { QuestionContext } from "../Context";
import QuestionReducer from "./QuestionReducer";
import {
  SAVE_EDIT_MODE,
  SAVE_ISLOADING_FORM,
  SAVE_ISLOADING_QUESTIONS,
  SAVE_ISVISIBLE_CREATE_FORM,
  SAVE_ISVISIBLE_EDIT_FORM,
  SAVE_IS_EMPTY_SURVEY,
  SAVE_IS_ERROR_SEND_SURVEY,
  SAVE_IS_SUCCESS_SEND_SURVEY,
  SAVE_IS_VALID_SURVEY,
  SAVE_PROGRESS,
  SAVE_QUESTIONS,
  SAVE_QUESTION_TYPES,
  SAVE_RESPONSE,
  SAVE_SURVEY_INFO,
  SAVE_SURVEY_SESSION_ID,
  SAVE_WIZARD,
} from "./types";

export const QuestionState = (props) => {
  const initialState = {
    surveySessionId: null,
    surveyInfo: { formatType: "layout-1" },
    channel: null,
    token: null,
    editMode: false,
    isFirstQuestion: false,
    questionTypes: OPTIONS,
    scaleNPS: SCALE_NPS,
    scaleCSAT: SCALE_CSAT,
    typeCSAT: TYPE_CSAT,
    typeNPS: TYPE_NPS,
    questions: [],
    questionSelected: null,
    stepWizard: 1,
    progress: 0,
    isValidSurvey: true,
    isEmptySurvey: false,
    isLoadingQuestions: true,
    isLoadingForm: false,
    isVisibleEditForm: false,
    isVisibleCreateForm: false,
    isErrorSendSurvey: false,
    isSuccessSendSurvey: false,
    responses: [],
    isSubmit: false,
    resultText:
      "¿Que tan importante es para ti la satisfacción de tus clientes?",
  };

  const navigate = useNavigate();
  const [state, dispatch] = useReducer(QuestionReducer, initialState);

  const saveIsLoadingQuestions = (isLoadingQuestions) => {
    dispatch({
      type: SAVE_ISLOADING_QUESTIONS,
      payload: isLoadingQuestions,
    });
  };

  const getQuestions = async (surveyId, channel = "DEFAULT") => {
    dispatch({ type: SAVE_SURVEY_INFO, payload: { surveyId, channel } });
    saveIsLoadingQuestions(true);

    await getQuestionsByIdSurvey(surveyId).then((response) => {
      if (response === "ERROR") {
        navigate("/");
        return dispatch({ type: SAVE_IS_VALID_SURVEY, payload: false });
      }
      if (response === "EMPTY" && channel !== "DEMO") navigate("/");

      if (response === "EMPTY") {
        return dispatch({ type: SAVE_IS_EMPTY_SURVEY, payload: true });
      }

      const { questions, survey } = response.data;
      if ((survey.isArchived || !survey.isActive) && channel !== "DEMO")
        navigate("/");

      let questionsMapper = questions.map((x) => {
        x.response = null;
        x.collapse = false;
        return x;
      });

      if (!survey.formatType) survey.formatType = "layout-1";

      if (survey.responseLimitExceeded && channel !== "DEMO") {
        questionsMapper = [
          {
            collapse: false,
            response: null,
            orden: 1,
            questionId: 999,
            typeId: 999,
            comment: null,
          },
        ];
      }

      if (channel !== "DEMO") {
        const request = { surveyId, channel };
        saveSurveySessionId(request, { ...survey, surveyId: surveyId });
      }

      localStorage.setItem(
        KEY_QUESTIONS_STORE,
        encryptMessage(JSON.stringify(questionsMapper))
      );

      dispatch({
        type: SAVE_SURVEY_INFO,
        payload: survey,
      });
      dispatch({
        type: SAVE_QUESTIONS,
        payload: questionsMapper,
      });
      dispatch({
        type: SAVE_IS_EMPTY_SURVEY,
        payload: questionsMapper.length <= 0,
      });
    });
  };

  const getQuestionTypes = async () => {
    await getQuestionsTypeList()
      .then((response) => {
        if (response.data) {
          const questionTypes = response.data.slice(0, 6);
          questionTypes.unshift({
            id: 0,
            name: "Selecciona tipo de pregunta",
          });
          saveQuestionTypes(questionTypes);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const saveSurveyInfo = (payload) => {
    dispatch({ type: SAVE_SURVEY_INFO, payload: payload });
  };

  const saveQuestions = (questions) => {
    dispatch({
      type: SAVE_QUESTIONS,
      payload: questions,
    });
  };

  const saveQuestionTypes = (questionTypes) => {
    dispatch({
      type: SAVE_QUESTION_TYPES,
      payload: questionTypes,
    });
  };

  const saveQuestion = (question) => {
    let questions = [...state.questions];
    questions = questions.filter((item) => item.questionId);
    saveQuestions(questions);
  };

  const saveEmptySurvey = (isEmptySyrvey) => {
    dispatch({ type: SAVE_IS_EMPTY_SURVEY, payload: isEmptySyrvey });
  };

  const saveResponse = (response) => {
    let responses = [...state.responses];
    let questions = [...state.questions];

    const isNewResponse = responses.find(
      (x) => x.questionId === response.questionId
    );

    if (!isNewResponse) {
      responses.push(response);
      questions = getQuestionResponseMap(questions, response);
    } else {
      responses = responses.map((x) => {
        if (x.questionId === response.questionId) {
          x.response = response.response;
          x.comment = response.comment;
        }
        return x;
      });
      questions = getQuestionResponseMap(questions, response);
    }

    dispatch({ type: SAVE_QUESTIONS, payload: questions });
    dispatch({ type: SAVE_RESPONSE, payload: responses });
  };

  const getQuestionResponseMap = (questions, response) => {
    return questions.map((x) => {
      if (x.questionId === response.questionId) {
        x.response = response.response;
      }
      return x;
    });
  };

  const updateStepWizard = (step) => {
    let divisor = state.questions.length - step;
    divisor = divisor === 0 ? 100 : divisor;

    const progress =
      divisor === 100
        ? divisor
        : -((divisor / state.questions.length) * 100) + 100;

    dispatch({ type: SAVE_WIZARD, payload: step });
    dispatch({ type: SAVE_PROGRESS, payload: progress });
  };

  const sendSurvey = async () => {
    dispatch({ type: SAVE_ISLOADING_FORM, payload: true });

    const request = {
      surveyId: state.surveyInfo.surveyId,
      sessionId: Cookies.get(state.surveyInfo.surveyId),
      channel: state.surveyInfo.channel,
      answers: [
        ...state.responses.map((item) => {
          const { questionId, typeQuestionId, response, comment } = item;
          return {
            questionId,
            typeQuestionId,
            answer: response.toString(),
            comment: comment,
          };
        }),
      ],
    };

    await saveResponseSurvey(request)
      .then((response) => {
        if (response === "OK") {
          dispatch({ type: SAVE_IS_SUCCESS_SEND_SURVEY, payload: true });
          dispatch({ type: SAVE_IS_ERROR_SEND_SURVEY, payload: false });
        }
        dispatch({ type: SAVE_ISLOADING_FORM, payload: false });
      })
      .catch((error) => {
        dispatch({ type: SAVE_ISLOADING_FORM, payload: false });
        dispatch({ type: SAVE_IS_SUCCESS_SEND_SURVEY, payload: false });
        dispatch({ type: SAVE_IS_ERROR_SEND_SURVEY, payload: true });
      });
  };

  const saveEditMode = (editMode, token, isFirstQuestion) => {
    dispatch({
      type: SAVE_EDIT_MODE,
      payload: { editMode, token, isFirstQuestion },
    });
  };

  const saveIsVisibleEditForm = (isVisibleEditForm) => {
    dispatch({
      type: SAVE_ISVISIBLE_EDIT_FORM,
      payload: isVisibleEditForm,
    });
  };

  const saveIsVisibleCreateForm = (isVisibleCreateForm) => {
    dispatch({
      type: SAVE_ISVISIBLE_CREATE_FORM,
      payload: isVisibleCreateForm,
    });
  };

  const saveSurveySessionId = async (request, survey) => {
    const { isRepeatModeSurvey, isTabletModeSurvey } = survey;
    const isRepeatThisSurvey = isRepeatModeSurvey || isTabletModeSurvey;
    if (!isRepeatThisSurvey) {
      const cookie = Cookies.get(survey.surveyId);
      cookie && navigate("/survey-finished");
    }

    const uuid = uuidv4();
    Cookies.set(request.surveyId, uuid, {
      expires: isRepeatThisSurvey ? 30 : 1,
    });
    request.identifier = uuid;

    const responseSession = await generateSurveySession(request);

    if (!isRepeatModeSurvey || isTabletModeSurvey) {
      if (responseSession === 400) navigate("/survey-finished");
      dispatch({
        type: SAVE_SURVEY_SESSION_ID,
        payload: { responseSession },
      });
    }
  };

  return (
    <QuestionContext.Provider
      value={{
        ...state,
        getQuestions,
        getQuestionTypes,
        saveQuestions,
        saveResponse,
        updateStepWizard,
        sendSurvey,
        saveEditMode,
        saveQuestion,
        saveIsVisibleEditForm,
        saveIsVisibleCreateForm,
        saveEmptySurvey,
        saveSurveyInfo,
        saveSurveySessionId,
        saveIsLoadingQuestions,
        saveQuestionTypes,
      }}
    >
      {props.children}
    </QuestionContext.Provider>
  );
};
