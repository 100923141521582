import { useContext } from "react";
import ContentWizard from "../../components/ContentWizard/ContentWizard";
import { QuestionContext } from "../../context/Context";
import Footer from "./Footer";
import Header from "./Header";

const Layout2 = () => {
  const questionContext = useContext(QuestionContext);

  return (
    <>
      <div className="layout_2">
        <Header></Header>
        <div className="container-fluid">
          <div id="form_container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div id="wizard_container">
                  <div id="top-wizard">
                    <div
                      id="progressbar"
                      className="ui-progressbar ui-widget ui-widget-content ui-corner-all"
                      role="progressbar"
                      aria-valuemin={0}
                      aria-valuemax={100}
                      aria-valuenow={questionContext.progress}
                    >
                      <div
                        className="ui-progressbar-value ui-widget-header ui-corner-left"
                        style={{ width: questionContext.progress + "%" }}
                      ></div>
                    </div>
                  </div>
                  <ContentWizard></ContentWizard>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </>
  );
};

export default Layout2;
