import { useContext, useEffect, useState } from "react";
import "../../../assets/sass/components/form-inputs.scss";
import { QuestionContext } from "../../../context/Context";

interface InputProps {
  question?: any;
  id?: string;
  value: string | number;
  name?: string;
  placeholder?: string;
  maxLength?: number;
  isError?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  label?: string;
  onKeyPress?: Function;
  onChange?: Function;
}

const Input = (props: InputProps) => {
  const questionContext = useContext(QuestionContext);
  const { surveyInfo } = questionContext;

  const [val, setVal] = useState(props.value);

  useEffect(() => {
    setVal(props.value);
    const { question } = props;
    if (
      question &&
      surveyInfo.channel !== "DEMO" &&
      question.response !== props.value
    ) {
      question.response = props.value;
      questionContext.saveResponse(question);
    }
  }, [props.value]);

  const saveResponse = (value: string) => {
    const { question } = props;
    if (
      question &&
      surveyInfo.channel !== "DEMO" &&
      question.response !== value
    ) {
      question.response = value;
      question.comment = value;
      questionContext.saveResponse(question);
    }
  };

  return (
    <>
      <div className="form-group w-100">
        <label>{props.label ?? ""}</label>
        <input
          className="form-style"
          id={props.id ?? "default-input"}
          type="text"
          name={props.name ?? "default-input"}
          value={val}
          placeholder={props.placeholder ?? ""}
          maxLength={props.maxLength ? props.maxLength : 100}
          disabled={props.disabled}
          onChange={(e) => {
            const value = e.target.value;
            setVal(value);
            props.onChange ? props.onChange(value) : saveResponse(value);
          }}
          onKeyPress={(e) => {
            if (props.onKeyPress) {
              props.onKeyPress(e);
            }
          }}
        />
        {props.isError && props.errorMessage && (
          <small className="text-danger">{props.errorMessage}</small>
        )}
      </div>
    </>
  );
};

export default Input;
