import React, { useContext, useEffect, useState } from "react";
import { QuestionFormContext } from "../../../context/Context";
import SelectableOptions, {
  Option,
} from "../../questionTypes/SelectableOptions/SelectableOptions";
import Input from "../../ui/Input/Input";

interface IMultipleOptionsForm {
  isEditable: boolean;
  allowMultiple?: boolean;
  errorMessage?: string;
}

const MultipleOptionForm = ({
  isEditable = true,
  allowMultiple = false,
  errorMessage,
}: IMultipleOptionsForm) => {
  const questionFormContext = useContext(QuestionFormContext);
  const {
    questionForm: { options },
    setQuestionOptions,
  } = questionFormContext;

  const [inputValue, setInputValue] = useState("");
  const [updateOption, setUpdateOption] = useState<Option | null>(null);
  const [errorMessageLocal, seteErrorMessageLocal] = useState(errorMessage);

  useEffect(() => {
    errorMessage && seteErrorMessageLocal(errorMessage);
  }, [errorMessage]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    handleAddOption();
  };

  const handleAddOption = () => {
    if (!inputValue.trim()) {
      seteErrorMessageLocal("No se puede agregar una opción vacía");
      return;
    }

    if (updateOption) {
      const updatedOptions = options.map((option: Option) => {
        if (option.id === updateOption.id) {
          return {
            ...option,
            value: inputValue,
          };
        }
        return option;
      });
      setQuestionOptions(updatedOptions);
      setUpdateOption(null);
      setInputValue("");
      return;
    }

    const isRepeated = options.some(
      (option: Option) => option.value === inputValue
    );

    if (isRepeated) {
      seteErrorMessageLocal("La opción ya existe");
      return;
    }

    const newOption = {
      id: crypto.randomUUID().replaceAll("-", ""),
      value: inputValue,
    };
    setQuestionOptions([...options, newOption]);
    seteErrorMessageLocal("");
    setInputValue("");
    setUpdateOption(null);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleAddOption();
    }
  };

  const handleOptionChange = (optionId: string) => {
    const updateOption = options.find(
      (option: Option) => option.id === optionId
    );
    if (!updateOption) return;
    setUpdateOption(updateOption);
    setInputValue(updateOption.value);
  };

  return (
    <div className="row mb-3">
      {isEditable && (
        <>
          <div className="col-9">
            <Input
              value={inputValue}
              onChange={(value: string) => setInputValue(value)}
              label="Opción"
              placeholder="Ingresa una opción"
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className="col-3 align-items-end">
            <button
              type="button"
              className="btn_1 btn-xl yellow text-center"
              onClick={handleAddOption}
            >
              {updateOption ? "Actualizar" : "Agregar"}
            </button>
          </div>
          {errorMessageLocal && (
            <div className="col-12">
              <small className="text-danger">{errorMessageLocal}</small>
            </div>
          )}
        </>
      )}
      <div className="col-12 mt-5">
        <SelectableOptions
          options={options}
          allowMultiple={allowMultiple}
          enableDragDrop={isEditable}
          onOptionChange={handleOptionChange}
        />
      </div>
    </div>
  );
};

export default MultipleOptionForm;
