import {
  faEdit,
  faPaperPlane,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { QuestionContext } from "../../context/Context";
import EndView from "../EndView/EndView";
import FirstView from "../FirstView/FirstView";
import ModalSurvey from "../Modal/Modal";
import QuestionEdit from "../QuestionEdit/QuestionEdit";
import SurveyUnavailable from "../SurveyUnavailable/SurveyUnavailable";
import Csat from "../questionTypes/Csat/Csat";
import Nps from "../questionTypes/Nps/Nps";
import SelectableOptions from "../questionTypes/SelectableOptions/SelectableOptions";
import Input from "../ui/Input/Input";
import Textarea from "../ui/Textarea/Textarea";

const ContentForm = () => {
  const questionContext = useContext(QuestionContext);
  const {
    questions,
    isSuccessSendSurvey,
    isErrorSendSurvey,
    isLoadingForm,
    editMode,
    isVisibleEditForm,
    isVisibleCreateForm,
    surveyInfo,
  } = questionContext;
  const [questionSelected, setQuestionSelected] = useState(null);
  const [isSendSurveyValid, setIsSendSurveyValid] = useState(true);

  useEffect(() => {
    if (!isVisibleEditForm) setQuestionSelected(null);
  }, [isVisibleEditForm]);

  const renderQuestion = (question: any, index: number) => {
    index = ++index;

    if (
      editMode &&
      question.questionId === questionSelected &&
      (isVisibleEditForm || isVisibleCreateForm)
    ) {
      return <QuestionEdit />;
    }

    const key = `${question.questionId}-${index}`;

    const stepsComponents: { [index: string]: any } = {
      "-1": <FirstView question={question} key={key} />,
      0: <EndView question={question} key={key} />,
      1: <Csat question={question} key={key} />,
      2: <Nps question={question} key={key} />,
      3: (
        <SelectableOptions
          question={question}
          options={question.options}
          allowMultiple={true}
          enableDragDrop={editMode}
          inlineOptions={false}
          key={key}
        />
      ),
      4: (
        <SelectableOptions
          question={question}
          options={question.options}
          allowMultiple={false}
          enableDragDrop={editMode}
          inlineOptions={false}
          key={key}
        />
      ),
      5: (
        <Input
          question={question}
          key={key}
          value=""
          placeholder="Escribe aquí..."
        />
      ),
      6: (
        <Textarea
          question={question}
          key={key}
          value=""
          placeholder="Escribe aquí..."
          rows={3}
        />
      ),
      999: <SurveyUnavailable />,
    };

    return stepsComponents[question.typeId] || <>Step does not exist</>;
  };

  const renderTitleQuestion = (question: any, index: number) => {
    return (
      <div className="mb-3">
        {question.typeId !== 0 && (
          <h2 className="main_question d-inline mb-3">
            {index}. {question.title}
          </h2>
        )}

        {editMode === true && question.questionId !== questionSelected && (
          <FontAwesomeIcon
            icon={faEdit}
            className="float-right cursor"
            onClick={() => {
              let positionWizard = questions.findIndex(
                (item: any) => item.questionId === question.questionId
              );
              positionWizard = ++positionWizard;

              questionContext.updateStepWizard(positionWizard);
              questionContext.saveIsVisibleEditForm(true);
              setQuestionSelected(question.questionId);
            }}
            style={{ color: "#00a4de", fontSize: "24px" }}
          />
        )}
      </div>
    );
  };

  const handleSubmit = () => {
    const isValidForm = questions.every((question: any) => {
      if (question.isMandatory && question.response === null) {
        return false;
      }
      return true;
    });
    setIsSendSurveyValid(isValidForm);
    if (isValidForm) questionContext.sendSurvey();
  };

  return (
    <>
      <form id="wrapped" className="animate__animated animate__fadeInRight">
        <input id="website" name="website" type="text" value="" />
        {questions.length > 0 && (
          <div id="middle-wizard" className="d-block">
            {questions.map((question: any, index: number) => {
              return (
                <div key={question.questionId}>
                  <div className="container-form-question">
                    {renderTitleQuestion(question, ++index)}
                    <div className="mt-5">
                      {renderQuestion(question, index)}
                    </div>
                  </div>
                  {!isSendSurveyValid &&
                    question.isMandatory &&
                    question.response == null &&
                    surveyInfo.channel !== "DEMO" && (
                      <p className="text-error animate__animated animate__fadeIn">
                        Por favor, completa esta pregunta. Es necesaria para
                        continuar.
                      </p>
                    )}
                  {questions.length > 1 && <hr />}
                </div>
              );
            })}
          </div>
        )}

        {surveyInfo.channel !== "DEMO" && (
          <div id="bottom-wizard">
            <button
              disabled={isLoadingForm}
              type="button"
              onClick={() => handleSubmit()}
              name="process"
              className="submit"
            >
              {isLoadingForm ? (
                <>
                  <span className="loading-text">Enviando encuesta...</span>
                  <FontAwesomeIcon
                    className="ml-1"
                    icon={faSpinner}
                    spin
                    style={{ color: "#fff" }}
                  />
                </>
              ) : (
                <>
                  Enviar Encuesta
                  <FontAwesomeIcon
                    className="ml-1"
                    icon={faPaperPlane}
                    style={{ color: "#fff" }}
                  />
                </>
              )}
            </button>
          </div>
        )}
      </form>
      <ModalSurvey isVisibleModal={isSuccessSendSurvey} />
      <ModalSurvey isVisibleModal={isErrorSendSurvey} />
    </>
  );
};

export default ContentForm;
