import { useContext } from "react";
import { QuestionContext, QuestionFormContext } from "../context/Context";
import { IErrorState } from "../context/QuestionFormContext/QuestionFormState";
import { ICreateQuestionRequest } from "../models/request/ICreateQuestions";
import {
  createQuestionSurvey,
  updateQuestionSurvey,
} from "../services/survey.service";

const useQuestionForm = () => {
  const questionContext = useContext(QuestionContext);
  const {
    questions,
    isVisibleCreateForm,
    surveyInfo,
    token,
    stepWizard,
    updateStepWizard,
    saveQuestions,
    getQuestions,
    saveIsVisibleEditForm,
    saveIsVisibleCreateForm,
  } = questionContext;

  const questionFormContext = useContext(QuestionFormContext);

  const { setErrorState, errorState } = questionFormContext;

  const handleCancel = () => {
    if (isVisibleCreateForm) {
      const newQuestions = [...questions];
      newQuestions.pop();
      updateStepWizard(stepWizard - 1);
      saveQuestions(newQuestions);
    }

    saveIsVisibleEditForm(false);
    saveIsVisibleCreateForm(false);
  };

  const isValidForm = (requestForm: ICreateQuestionRequest): boolean => {
    let isValidForm = true;

    const newErrorState = [];

    if (requestForm.questionType === 0) {
      const newError: IErrorState = {
        errorForm: true,
        errorMessageForm: "Debes seleccionar un tipo de pregunta",
        errorField: "questionType",
        errorFetchForm: false,
      };

      newErrorState.push(newError);

      isValidForm = false;
    }

    if (requestForm.questionTitle.trim().length < 5) {
      const newError: IErrorState = {
        errorForm: true,
        errorField: "questionTitle",
        errorMessageForm:
          "Debes ingresar la pregunta con al menos 5 caracteres",
        errorFetchForm: false,
      };
      newErrorState.push(newError);

      isValidForm = false;
    }

    if (requestForm.questionType === 3 || requestForm.questionType === 4) {
      if (requestForm.options.length < 2) {
        const newError: IErrorState = {
          errorForm: true,
          errorField: "options",
          errorMessageForm: "Debes ingresar al menos dos opciones",
          errorFetchForm: false,
        };

        newErrorState.push(newError);
      }
    }

    setErrorState(newErrorState);
    return isValidForm;
  };

  const saveQuestionFetch = async (
    request: ICreateQuestionRequest,
    actualQuestion: any
  ) => {
    if (!isValidForm(request)) return;
    setErrorState([]);

    try {
      let response = null;
      if (isVisibleCreateForm) {
        response = await createQuestionSurvey(request, token);
      } else {
        response = await updateQuestionSurvey(
          request,
          token,
          actualQuestion.questionId
        );
      }

      window.parent.postMessage(
        {
          type: "GET_QUESTIONS",
          isSuccessRequest: response !== null,
        },
        "*"
      );
      if (response !== null) {
        getQuestions(surveyInfo.surveyId, surveyInfo.channel);
        saveIsVisibleEditForm(false);
        saveIsVisibleCreateForm(false);
      }
    } catch (error) {
      console.error("Error saving question", error);
    } finally {
      // TODO VALIDAR ENVIAR ERROR DE SERVICIO CON EL CONTEXT
      // setIsLoadingForm(false);
    }
  };

  return {
    saveQuestionFetch,
    handleCancel,
  };
};

export default useQuestionForm;
