import { useContext } from "react";
import logoDark from "../../assets/img/logos//dark-large-logo.png";
import logoLight from "../../assets/img/logos/light-large-logo.png";
import ContentForm from "../../components/ContentForm/ContentForm";
import { QuestionContext } from "../../context/Context";
import Footer from "./Footer";
import Header from "./Header";
import "./layout-3.scss";

const Layout3 = () => {
  const questionContext = useContext(QuestionContext);
  const { surveyInfo } = questionContext;

  const isValidPremiumPlanLogo =
    surveyInfo.plan?.planId !== 1 && surveyInfo.logoUrl;
  const isValidFreePlanLogo = surveyInfo.plan?.planId === 1;
  const containerClassName = `mx-auto px-4 px-md-5 survey ${
    isValidPremiumPlanLogo || isValidFreePlanLogo ? "pt-5" : ""
  }`;

  return (
    <>
      <div className="layout-3">
        <div className={"bg-presentation bg-color-" + surveyInfo.mainBgType}>
          {surveyInfo.plan?.planId === 1 && <Header></Header>}
        </div>
        <div className={containerClassName}>
          <div className="wd-heading text-center">
            {surveyInfo.plan?.planId !== 1 && surveyInfo.logoUrl && (
              <img
                className="img-fluid image_presentation d-inline ml-auto"
                src={surveyInfo.logoUrl}
                alt=""
              ></img>
            )}

            {surveyInfo.plan?.planId === 1 && (
              <img
                className="img-fluid image_presentation_default d-inline ml-auto"
                src={surveyInfo.mainBgType === "light" ? logoDark : logoLight}
                alt=""
              ></img>
            )}

            <h2
              className={
                surveyInfo.mainBgType === "light" ? "title_dark" : "title_light"
              }
            >
              {surveyInfo.name}
            </h2>
            {/* <small className="description_a">{surveyInfo.description}</small> */}
            <p className="sub_title_a">{surveyInfo.presentation_text}</p>
          </div>

          <div className="mb-5">
            <div className="justify-content-center">
              <div
                id="wizard_container"
                className={"bg-color-" + surveyInfo.contentBgType}
              >
                <ContentForm></ContentForm>
              </div>
            </div>
          </div>
        </div>
        {surveyInfo.channel !== "DEMO" && <Footer></Footer>}
      </div>
    </>
  );
};

export default Layout3;
