import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { QuestionContext } from "../context/Context";
import usePanelAdminBinding from "../hooks/usePanelAdminBinding";
import Layout1 from "../layouts/layout1/Layout1";
import Layout2 from "../layouts/layout2/Layout2";
import Layout3 from "../layouts/layout3/Layout3";
import LoadingPage from "./LoadingPage";

const SurveyContainer = () => {
  const { surveyId, channel } = useParams();
  const questionContext = useContext(QuestionContext);
  const { surveyInfo, saveSurveyInfo, getQuestions, getQuestionTypes } =
    questionContext;

  // Este hook valida el microfront de la encuesta con el panel administrativo
  const { bindingPanelMF } = usePanelAdminBinding();

  useEffect(() => {
    const fetchData = async () => {
      await getQuestions(surveyId, channel);
      await getQuestionTypes();
    };

    fetchData();
  }, [surveyId, channel]);

  return (
    <>
      {bindingPanelMF()}
      {!questionContext.isLoadingQuestions ? (
        <div className="h-100">
          {surveyInfo.formatType === "layout-1" && <Layout1 />}
          {surveyInfo.formatType === "layout-2" && <Layout2 />}
          {surveyInfo.formatType === "layout-3" && <Layout3 />}
        </div>
      ) : (
        <LoadingPage></LoadingPage>
      )}
    </>
  );
};

export default SurveyContainer;
