import { useContext } from "react";
import { QuestionContext } from "../../context/Context";

const Footer = () => {
  const questionContext = useContext(QuestionContext);
  const { surveyInfo } = questionContext;

  const currentTime = new Date();
  const year = currentTime.getFullYear();

  return (
    <footer className="footer_in clearfix">
      <div className="container">
        <p>© {year} Empati</p>
        {surveyInfo.plan?.planId === 1 && (
          <ul>
            <li>
              <a href="https://empati.cl" className="animated_link">
                Crea encuestas como esta
              </a>
            </li>
            <li>
              <a href="https://hware.cl/team" className="animated_link">
                Equipo humanware
              </a>
            </li>
          </ul>
        )}
      </div>
    </footer>
  );
};

export default Footer;
