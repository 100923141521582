import {
  faAngleLeft,
  faAngleRight,
  faArrowRight,
  faEdit,
  faPaperPlane,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useWizard } from "use-wizard";
import { QuestionContext, QuestionFormContext } from "../../context/Context";
import EndView from "../EndView/EndView";
import FirstView from "../FirstView/FirstView";
import ModalSurvey from "../Modal/Modal";
import QuestionEdit from "../QuestionEdit/QuestionEdit";
import SurveyUnavailable from "../SurveyUnavailable/SurveyUnavailable";
import Csat from "../questionTypes/Csat/Csat";
import Nps from "../questionTypes/Nps/Nps";
import SelectableOptions from "../questionTypes/SelectableOptions/SelectableOptions";
import Input from "../ui/Input/Input";
import Textarea from "../ui/Textarea/Textarea";
import "./content-wizard.scss";

const ContentWizard = () => {
  const questionContext = useContext(QuestionContext);
  const questionFormContext = useContext(QuestionFormContext);

  const navigate = useNavigate();
  const [step, wizard] = useWizard();
  const [isRenderQuestion, setIsRenderComponent] = useState(true);
  const [isMandatoryTextActive, setIsMandatoryTextActive] = useState(false);

  const totalQuestions = questionContext.questions.length;
  const {
    surveyInfo,
    editMode,
    isVisibleEditForm,
    isVisibleCreateForm,
    isSubmit,
    questions,
    isErrorSendSurvey,
    isSuccessSendSurvey,
    stepWizard,
    isLoadingForm,
    saveIsVisibleEditForm,
    updateStepWizard,
    sendSurvey,
  } = questionContext;

  const { questionForm } = questionFormContext;

  useEffect(() => {
    validateIsRenderedComponent();
  });

  const previousStep = (step: any) => {
    setIsRenderComponent(false);
    wizard.previousStep();
    updateStepWizard(--step);
    validateIsRenderedComponent();
    editMode && saveIsVisibleEditForm(false);
  };

  const nextStep = (step: any) => {
    const question = getActualQuestion();
    setIsMandatoryTextActive(false);
    if (
      question.isMandatory &&
      !question.response &&
      surveyInfo.channel !== "DEMO"
    ) {
      setIsMandatoryTextActive(true);
      return;
    }

    setIsRenderComponent(false);
    wizard.nextStep();
    validateIsRenderedComponent();
    updateStepWizard(++step);
    editMode && saveIsVisibleEditForm(false);
  };

  const validateIsRenderedComponent = () => {
    setTimeout(() => {
      setIsRenderComponent(true);
    }, 1);
  };

  const getActualStep = () => {
    let actualStep = null;

    questions.forEach((question: any, index: number) => {
      index = ++index;
      if (stepWizard === index) {
        if (editMode && (isVisibleEditForm || isVisibleCreateForm)) {
          actualStep = <QuestionEdit />;
          return;
        }

        const key = `${question.questionId}-${index}`;

        const stepsComponents: { [index: string]: any } = {
          "-1": <FirstView question={question} key={key} />,
          0: <EndView question={question} key={key} />,
          1: <Csat question={question} key={key} />,
          2: <Nps question={question} key={key} />,
          3: (
            <SelectableOptions
              question={question}
              options={question.options}
              allowMultiple={true}
              enableDragDrop={false}
              inlineOptions={false}
              key={key}
            />
          ),
          4: (
            <SelectableOptions
              question={question}
              options={question.options}
              allowMultiple={false}
              enableDragDrop={false}
              inlineOptions={false}
              key={key}
            />
          ),
          5: (
            <Input
              question={question}
              key={key}
              value=""
              placeholder="Escribe aquí..."
            />
          ),
          6: (
            <Textarea
              question={question}
              key={key}
              value=""
              placeholder="Escribe aquí..."
              rows={3}
            />
          ),
          999: <SurveyUnavailable />,
        };

        actualStep = stepsComponents[question.typeId] || (
          <>Step does not exist</>
        );
      }
    });

    return actualStep;
  };

  const getActualQuestion = () => {
    const question = questions.find((question: any, index: number) => {
      index = ++index;
      if (stepWizard === index) {
        return question;
      }
      return null;
    });

    return question ?? questions[0] ?? null;
  };

  const renderTitleQuestion = () => {
    return (
      <div className="mb-3">
        {getActualQuestion() && getActualQuestion().typeId !== 0 && (
          <h3 className="main_question d-inline">
            <FontAwesomeIcon
              icon={faArrowRight}
              className="mr-2"
              style={{ color: "#00a4de" }}
            />
            {stepWizard}. {getActualQuestion().title}
          </h3>
        )}
        {editMode === true && !isVisibleEditForm && (
          <FontAwesomeIcon
            icon={faEdit}
            className="float-right cursor"
            onClick={() => saveIsVisibleEditForm(true)}
            style={{ color: "#00a4de", fontSize: "24px" }}
          />
        )}
      </div>
    );
  };

  const renderQuestions = () => {
    const actualStep = getActualStep();
    return (
      <>
        <div className="step animate__animated animate__fadeInRight">
          {!isVisibleEditForm &&
            !isVisibleCreateForm &&
            questions[0].typeId !== 999 &&
            renderTitleQuestion()}
          {actualStep}
        </div>
      </>
    );
  };

  if (isSubmit === true) {
    navigate("/survey-result");
  }

  return (
    <>
      <form id="wrapped" className="animate__animated animate__fadeInRight">
        <input id="website" name="website" type="text" value="" />
        <div id="middle-wizard">{isRenderQuestion && renderQuestions()}</div>
        {isMandatoryTextActive && surveyInfo.channel !== "DEMO" && (
          <p className="text-error animate__animated animate__fadeIn">
            Por favor, completa esta pregunta. Es necesaria para continuar.
          </p>
        )}
        {!isVisibleEditForm && !isVisibleCreateForm && (
          <div id="bottom-wizard">
            {stepWizard > 1 && (
              <button
                type="button"
                name="backward"
                className="backward mb-2 mb-md-0 mr-3"
                onClick={() => previousStep(stepWizard)}
              >
                <FontAwesomeIcon className="mr-1" icon={faAngleLeft} />{" "}
                Retroceder
              </button>
            )}
            {stepWizard < totalQuestions && (
              <button
                type="button"
                name="forward"
                className="forward"
                onClick={() => nextStep(stepWizard)}
              >
                Avanzar
                <FontAwesomeIcon
                  className="ml-1"
                  icon={faAngleRight}
                  style={{ color: "#fff" }}
                />
              </button>
            )}

            {stepWizard === totalQuestions &&
              !editMode &&
              questions[0].typeId !== 999 && (
                <button
                  disabled={isLoadingForm}
                  type="button"
                  onClick={() => {
                    const question = getActualQuestion();
                    if (question.isMandatory && null == question.response) {
                      setIsMandatoryTextActive(true);
                      return;
                    }
                    sendSurvey();
                  }}
                  name="process"
                  className="submit"
                >
                  {isLoadingForm ? (
                    <>
                      <span className="loading-text">Enviando encuesta...</span>
                      <FontAwesomeIcon
                        className="ml-1"
                        icon={faSpinner}
                        spin
                        style={{ color: "#fff" }}
                      />
                    </>
                  ) : (
                    <>
                      Enviar Encuesta
                      <FontAwesomeIcon
                        className="ml-1"
                        icon={faPaperPlane}
                        style={{ color: "#fff" }}
                      />
                    </>
                  )}
                </button>
              )}
          </div>
        )}
        <ModalSurvey isVisibleModal={isSuccessSendSurvey} />
        <ModalSurvey isVisibleModal={isErrorSendSurvey} />
      </form>
    </>
  );
};

export default ContentWizard;
