import {
  SAVE_EDIT_MODE,
  SAVE_ISLOADING_FORM,
  SAVE_ISLOADING_QUESTIONS,
  SAVE_ISVISIBLE_CREATE_FORM,
  SAVE_ISVISIBLE_EDIT_FORM,
  SAVE_IS_EMPTY_SURVEY,
  SAVE_IS_ERROR_SEND_SURVEY,
  SAVE_IS_SUBMIT,
  SAVE_IS_SUCCESS_SEND_SURVEY,
  SAVE_IS_VALID_SURVEY,
  SAVE_PROGRESS,
  SAVE_QUESTIONS,
  SAVE_QUESTION_TYPES,
  SAVE_RESPONSE,
  SAVE_SURVEY_INFO,
  SAVE_SURVEY_SESSION_ID,
  SAVE_WIZARD,
} from "./types";

export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case SAVE_QUESTIONS:
      return {
        ...state,
        questions: payload,
        isLoadingQuestions: false,
      };
    case SAVE_EDIT_MODE:
      return {
        ...state,
        editMode: payload.editMode,
        surveyInfo: { ...state.surveyInfo },
        token: payload.token,
        isFirstQuestion: payload.isFirstQuestion,
      };
    case SAVE_SURVEY_INFO:
      return {
        ...state,
        channel: payload.channel,
        surveyInfo: {
          ...state.surveyInfo,
          ...payload,
        },
      };
    case SAVE_WIZARD:
      return {
        ...state,
        stepWizard: payload,
      };
    case SAVE_PROGRESS:
      return {
        ...state,
        progress: payload,
      };
    case SAVE_ISLOADING_QUESTIONS:
      return {
        ...state,
        isLoadingQuestions: payload,
      };
    case SAVE_ISLOADING_FORM:
      return {
        ...state,
        isLoadingForm: payload,
      };
    case SAVE_ISVISIBLE_EDIT_FORM:
      return {
        ...state,
        isVisibleEditForm: payload,
        isVisibleCreateForm: false,
      };
    case SAVE_ISVISIBLE_CREATE_FORM:
      return {
        ...state,
        isVisibleCreateForm: payload,
        isVisibleEditForm: false,
      };
    case SAVE_RESPONSE:
      return {
        ...state,
        responses: payload,
      };
    case SAVE_IS_VALID_SURVEY:
      return {
        ...state,
        isValidSurvey: payload,
      };
    case SAVE_IS_EMPTY_SURVEY:
      return {
        ...state,
        isEmptySurvey: payload,
      };
    case SAVE_IS_SUBMIT:
      return {
        ...state,
        isSubmit: payload.isSubmit,
        resultText: payload.resultText,
      };
    case SAVE_IS_ERROR_SEND_SURVEY:
      return {
        ...state,
        isErrorSendSurvey: payload,
      };
    case SAVE_IS_SUCCESS_SEND_SURVEY:
      return {
        ...state,
        isSuccessSendSurvey: payload,
      };
    case SAVE_SURVEY_SESSION_ID:
      return {
        ...state,
        surveySessionId: payload.surveySessionId,
      };
    case SAVE_QUESTION_TYPES:
      return {
        ...state,
        questionTypes: payload,
      };

    default:
      return state;
  }
};
